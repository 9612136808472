.board-status-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 30px;
    z-index: 1000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(255, 255, 255);
    visibility: hidden;

    &.offline {
        background-color: rgba(250, 56, 56, 0.5);
    }

    &.online {
        background-color: rgba(154, 197, 65, 0.5);
    }

    &.animate-in {
        visibility: visible;
        animation: slideIn 1s forwards;
    }

    &.animate-out {
        animation: slideOut 1s forwards;
    }
}

@keyframes slideIn {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideOut {
    0% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-100%);
        visibility: hidden;
    }
}
