.carousel-img {
  max-height: 200px;
  width: auto !important;
  display: block;
  margin: 0 auto;
}

.carousel-img.small {
  max-height: 150px;
}

.code {
  height: 150px;
  line-height: 150px !important;
  font-weight: 700 !important;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  color: #5aab30;
  border-radius: 40px;
  background: rgba(255, 255, 255, 0.5);
}

.next-player-message {
  color: black;
  line-height: 50px;
  font-variant: small-caps;
}

.next-player-name {
  color: black;
  text-transform: uppercase;
  line-height: 100px !important;
  font-variant: small-caps;
}

.header {
  height: 150px;
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
}
