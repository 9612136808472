.take-over-container {
    position: fixed;
    bottom: 2rem;
    display: flex;
    justify-content: center;
    z-index: 1000;
    opacity: 1;
}

.foggy-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
}

//show class with enter animation
.take-over-container.show {
    animation: slide-up 0.5s ease-in-out forwards;
}

.take-over-container.test {
    transform: translateY(130%);
}

//hide class with exit animation
.take-over-container.hide {
    animation: slide-down 0.5s ease-in-out forwards;
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0);
    }
}

@keyframes slide-down {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(130%);
    }
}